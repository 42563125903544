import React, { FC, useEffect, useRef, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { Form, Formik, FormikValues } from "formik";
import { createAccountSchemas, JobRequest, inits } from "./NuevoEmpleoHelper";
import { Paso1 } from "./Paso1";
import { Paso2 } from "./Paso2";
import { Paso3 } from "./Paso3";
import Paso4 from "./Paso4";
import { Paso5 } from "./Paso5";
import Paso6 from "./Paso6";
import Paso7 from "./Paso7";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Paso8 } from "./Paso8";
import Paso9 from "./Paso9";
import Paso10 from "./Paso10";
import { StartLoading, StopLoading } from "../../../components/support/Script";
import { useNavigate } from "react-router-dom";
import Alerts, { useFlash } from "../../../components/support/Alerts";
import { ConstructFormOnSubmitNotState } from "../../../components/inputs/GeneralInput";
import { MobileStepper } from "@mui/material";
import { BaseDocumento } from "./PdfSolicitud";
import { pdf } from "@react-pdf/renderer";
interface obj_detail {
  codigo_proceso: string;
  key: string;
}

const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

const HorizontalSteps: FC = () => {
  const navigate = useNavigate();
  const { setAlert } = useFlash();
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0]);
  const [initValues] = useState<JobRequest>(inits);
  const [isSubmitButton, setSubmitButton] = useState(false);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(
      createAccountSchemas[stepper.current.currentStepIndex - 1]
    );

    setSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totalStepsNumber
    );
  };
  const getFormat = async () => {
    const url = process.env.REACT_APP_URL_API_FORMULARIO?.toString() ?? "";
    var retorno = null;
    if (url !== "") {
      StartLoading();
      var details: obj_detail = {
        codigo_proceso: "SOLSOLICITUD",
        key: process.env.REACT_APP_TOKEN_FORMULARIO?.toString() ?? "123",
      };
      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(
          details[property as keyof obj_detail]
        );
        formBody.push(encodedKey + "=" + encodedValue);
      }
      const formBodyFetch = formBody.join("&");
      await fetch(`${url}/ticket/formatoSolicitud`, {
        method: "POST",
        mode: "cors",
        body: formBodyFetch,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          StopLoading();
          retorno = response;
        })
        .catch((e) => {
          if (e.message.search("Failed to fetch") >= 0) {
            setAlert({
              type: "danger",
              message: "Error de consumo hacia el web service",
            });
          } else {
            setAlert({ type: "danger", message: e.message });
          }
          StopLoading();
        });
    } else {
      setAlert({ type: "danger", message: "Error en traer formulario" });
    }
    return retorno;
  };
  const submitStep = (values: JobRequest, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext();
    } else {
      const url = process.env.REACT_APP_URL_API_FORMULARIO?.toString() ?? "";
      if (url !== "") {
        StartLoading();
        getFormat().then((response) => {
          if (
            response !== undefined &&
            response !== null &&
            "data" in response
          ) {
            const data = ConstructFormOnSubmitNotState(
              response["data"],
              values
            );
            pdf(<BaseDocumento obj={values} />)
              .toBlob()
              .then(async (blob) => {
                var formBody = [];
                const base64 = await fileToBase64(blob);
                const details = {
                  key_old:
                    process.env.REACT_APP_TOKEN_FORMULARIO?.toString() ?? "123",
                  key: "123ABC",
                  codigo_proceso: "SOLSOLICITUD",
                  correo: values.correoElectronico,
                  departamento: "",
                  grupo: "",
                  json_valores: JSON.stringify({ data: data }),
                  json_solicitud: JSON.stringify(values),
                  pdf_solicitud: base64,
                };
                for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(
                    details[property as keyof obj_detail]
                  );
                  formBody.push(encodedKey + "=" + encodedValue);
                }
                const formBodyFetch = formBody.join("&");
                fetch(`${url}/ticket/solicita`, {
                  method: "POST",
                  mode: "cors",
                  body: formBodyFetch,
                  headers: {
                    "Content-Type":
                      "application/x-www-form-urlencoded;charset=UTF-8",
                  },
                })
                  .then((response) => response.json())
                  .then((data) => {
                    StopLoading();
                    if (
                      (data.valido === "1" ||
                        data.valido === true ||
                        data.valido === 1) &&
                      data.mensaje === "SOLICITUD REALIZADA CON EXITO"
                    ) {
                      setAlert({
                        type: "success",
                        message:
                          "Solicitud enviada correctamente, se redirigirá a la pantalla de inicio",
                      });
                      setTimeout(() => {
                        navigate("/", { replace: true });
                      }, 4000);
                    } else {
                      setAlert({ type: "danger", message: data.mensaje });
                    }
                  })
                  .catch((e) => {
                    setAlert({ type: "danger", message: e.message });
                    StopLoading();
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
      } else {
        setAlert({ type: "danger", message: "URL_GATE WAY no definido" });
      }
    }

    setSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totalStepsNumber
    );

    setCurrentSchema(
      createAccountSchemas[stepper.current.currentStepIndex - 1]
    );
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  return (
    <div className="card">
      <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 w-lg-225px me-5">
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/copher/logo.jpg")}
          className="d-none d-md-inline d-lg-inline h-50px theme-light-show"
        />
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/copher/logo.jpg")}
          className="d-md-none d-lg-none h-30px"
        />
      </div>
      <div className="card-body">
        <h2 style={{ textAlign: "center" }}>SOLICITUD DE EMPLEO</h2>
        <div
          ref={stepperRef}
          className="stepper stepper-links d-flex flex-column pt-15"
          id="kt_create_account_stepper"
        >
          <div className="stepper-nav mb-5  d-none d-lg-flex">
            <div className="stepper-item current" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Información Personal</h3>
            </div>
            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Datos Familiares</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Información Socioeconómica</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Educación</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Experiencia Laboral</h3>
            </div>
            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Actividades Extralaborales</h3>
            </div>
            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Salud en General</h3>
            </div>
            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Condiciones de Trabajo</h3>
            </div>
            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Referencias Personales</h3>
            </div>
            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Referencias Laborales</h3>
            </div>
          </div>
          <Alerts />
          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}
          >
            {({ errors, touched, values, setTouched }) => (
              <Form
                className="mx-auto mw-800px w-100 pt-15 pb-10"
                id="kt_create_account_form"
              >
                <div className="current" data-kt-stepper-element="content">
                  <Paso1
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>

                <div data-kt-stepper-element="content">
                  <Paso2
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>

                <div data-kt-stepper-element="content">
                  <Paso3
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>

                <div data-kt-stepper-element="content">
                  <Paso4
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>

                <div data-kt-stepper-element="content">
                  <Paso5
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>
                <div data-kt-stepper-element="content">
                  <Paso6
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>
                <div data-kt-stepper-element="content">
                  <Paso7
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>
                <div data-kt-stepper-element="content">
                  <Paso8
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>
                <div data-kt-stepper-element="content">
                  <Paso9
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>
                <div data-kt-stepper-element="content">
                  <Paso10
                    errors={errors}
                    touched={touched}
                    values={values}
                    setTouched={setTouched}
                    step={
                      stepper.current?.currentStepIndex !== undefined
                        ? stepper.current?.currentStepIndex
                        : 0
                    }
                  />
                </div>
                <div className="d-flex flex-stack pt-15">
                  <div className="mr-2">
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      style={{ backgroundColor: "#182C59" }}
                      data-kt-stepper-action="previous"
                    >
                      <KTIcon iconName="arrow-left" className="fs-4 me-1" />
                      Atras
                    </button>
                  </div>

                  <MobileStepper
                    variant="dots"
                    steps={10}
                    className="d-lg-none"
                    activeStep={
                      stepper?.current?.currentStepIndex !== null &&
                      stepper?.current?.currentStepIndex !== undefined
                        ? stepper?.current?.currentStepIndex - 1
                        : 0
                    }
                    position="static"
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={<></>}
                    backButton={<></>}
                  />
                  <div>
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary me-3"
                      style={{ backgroundColor: "#182C59" }}
                    >
                      <span className="indicator-label">
                        {!isSubmitButton && "Continuar"}
                        {isSubmitButton && "Guardar"}
                        <KTIcon
                          iconName="arrow-right"
                          className="fs-3 ms-2 me-0"
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export { HorizontalSteps };
