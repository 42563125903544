/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { StatisticsWidget5 } from "../../../_metronic/partials/widgets";
const DashboardWrapper: FC = () => {
  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <div className="card-header cursor-pointer">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Hermanos Copher</h3>
        </div>
      </div>

      <div className="card-body p-9">
        <div className="row">
          <div className="col-xl-6 col-md-6">
            <StatisticsWidget5
              link="/solicitud_vacaciones"
              className="card-xl-stretch mb-xl-8"
              svgIcon="sun"
              color="#1874F7"
              iconColor="white"
              title="Vacaciones"
              titleColor="white"
              description="Solicitud y Gestión de vacaciones"
              descriptionColor="white"
            />
          </div>
          <div className="col-xl-6 col-md-6">
            <StatisticsWidget5
              link="/recibo_pago"
              className="card-xl-stretch mb-xl-8"
              svgIcon="tablet-ok"
              color="#172D59"
              iconColor="white"
              title="Recibos de Pago"
              titleColor="white"
              description="Consulta de Recibos de Pago"
              descriptionColor="white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { DashboardWrapper };
