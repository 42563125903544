import React, { FC, useRef } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import ReactToPrint from "react-to-print";
export interface ReciboPagoDetailInterface {
  row: any;
}
const ReciboPagoDetail: FC<ReciboPagoDetailInterface> = ({ row }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  if (row.cabecera === undefined) {
    return <></>;
  }
  const fecha_inicio = new Date(row.cabecera.fecha_inicio).toLocaleDateString();
  const fecha_fin = new Date(row.cabecera.fecha_fin).toLocaleDateString();
  const fecha_actual = new Date().toLocaleDateString();
  const getPageMargins = () => {
    return `@page { margin: 30mm !important; }`;
  };
  var suma_ingreso = 0;
  var suma_egreso = 0;
  return (
    <>
      <div
        className="modal fade"
        id="kt_modal_recibo_detail"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content rounded">
            <div className="modal-header justify-content-end border-0 pb-0">
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-sm btn-icon btn-active-color-primary">
                    <KTIcon iconName="printer" className="fs-1" />
                  </button>
                )}
                content={() => componentRef.current}
              />
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div ref={componentRef}>
              <style>{getPageMargins()}</style>
              <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <div className="d-flex justify-content-center">
                      <h3>
                        {row.cabecera.empresa} NIT -{row.cabecera.nit}
                      </h3>
                    </div>
                    <div className="d-flex justify-content-center">
                      <h4>
                        Pago comprendido del {fecha_inicio + " al " + fecha_fin}
                      </h4>
                    </div>
                    <div className="d-flex justify-content-center">
                      <h4>
                        Boleta de Pago Mensual Administrativa Planilla NO.{" "}
                        {row.cabecera.no_planilla}
                      </h4>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <table className="table table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <b>Colaborador</b>
                          </td>
                          <td colSpan={3}>
                            {row.cabecera.codigo_empleado +
                              "- " +
                              row.cabecera.empleado +
                              "- NIT " +
                              row.cabecera.nit_empleado}
                          </td>
                        </tr>
                        <tr>
                          <td width={"20%"}>
                            <b>Salario Base</b>
                          </td>
                          <td width={"40%"}>
                            {parseFloat(
                              row.cabecera.salario_base
                            ).toLocaleString("es-GT", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td width={"20%"}>
                            <b>Boleta Número</b>
                          </td>
                          <td width={"20%"}>
                            {row.cabecera.no_planilla +
                              row.cabecera.codigo_empleado}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Puesto</b>
                          </td>
                          <td>{row.cabecera.puesto}</td>
                          <td>
                            <b>Días Trabajados</b>
                          </td>
                          <td>{row.cabecera.dias_laborados}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>{row.cabecera.departamento}</td>
                          <td>
                            <b>Días Vacaciones</b>
                          </td>
                          <td>{row.cabecera.dias_vacaciones}</td>
                        </tr>
                        <tr>
                          <td colSpan={4}></td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="" style={{ width: "100%" }}>
                      <thead>
                        <tr
                          style={{
                            borderBottomWidth: 2,
                            borderColor: "black",
                            borderStyle: "solid",
                          }}
                        >
                          <td width={"50%"}></td>
                          <td width={"25%"}>
                            <b>Ingreso</b>
                          </td>
                          <td width={"25%"}>
                            <b>Egresos</b>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {row.detalle.map(function (detail: any, index: any) {
                          suma_ingreso +=
                            detail.identificar === "+"
                              ? parseFloat(detail.monto)
                              : 0;
                          suma_egreso +=
                            detail.identificar === "-"
                              ? parseFloat(detail.monto)
                              : 0;
                          return (
                            <tr key={index + "_key_detalle"}>
                              <td>{detail.descripcion}</td>
                              <td>
                                {detail.identificar === "+"
                                  ? parseFloat(detail.monto).toLocaleString(
                                      "es-GT",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )
                                  : ""}
                              </td>
                              <td>
                                {detail.identificar === "-"
                                  ? parseFloat(detail.monto).toLocaleString(
                                      "es-GT",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr
                          style={{
                            borderTopWidth: 2,
                            borderColor: "black",
                            borderStyle: "solid",
                          }}
                        >
                          <td>
                            <b>Totales</b>
                          </td>
                          <td>
                            <b>
                              {suma_ingreso.toLocaleString("es-GT", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </b>
                          </td>
                          <td>
                            <b>
                              {suma_egreso.toLocaleString("es-GT", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xl-6"></div>
                  <div className="col-md-6 col-xl-6">
                    <table className="table">
                      <tbody
                        style={{
                          borderBottomWidth: 2,
                          borderColor: "black",
                          borderStyle: "solid",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              borderTopWidth: 2,
                              borderBottomWidth: 2,
                              borderLeftWidth: 2,
                              borderColor: "black",
                              borderStyle: "solid",
                            }}
                          >
                            <b>Líquido a Pagar</b>
                          </td>
                          <td
                            style={{
                              borderTopWidth: 2,
                              borderBottomWidth: 2,
                              borderRightWidth: 2,
                              borderColor: "black",
                              borderStyle: "solid",
                            }}
                          >
                            <b>
                              {(suma_ingreso - suma_egreso).toLocaleString(
                                "es-GT",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xl-6">
                    <h5>Depositado en Cuenta {row.cabecera.no_cuenta}</h5>
                    Fecha {fecha_actual}
                    <br />
                  </div>
                  <div className="col-md-6 col-xl-6">
                    <div className="d-flex justify-content-end">
                      <br />
                      __________________________________________
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReciboPagoDetail;
