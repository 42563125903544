import axios from 'axios'
import {UserModel} from './_models'
import { actualiza_usuario } from '../../../routing/AppRoutes'

const API_URL = process.env.REACT_APP_URL
const url = process.env.REACT_APP_URL_API_APOYO?.toString() ?? ''
const key = process.env.REACT_APP_TOKEN_FORMULARIO?.toString() ?? '123'
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/login_ms/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string, setCurrentUser: any) {
  let obj = {auth: {api_token: ''}, user: {fullname: '', id: '', username: '', codigoPlanilla: '',password: '', email: '', first_name: '', last_name: ''}};
  await fetch(`${url}/rh/data`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      planillaid: '',
      fecha_inicio: '',
      fecha_fin: '',
      valor_texto: email,
      valor_numero: '',
      valor_texto2: password,
      valor_numero2: '',
      proyecto_id: '',
      token_app: process.env.REACT_APP_TOKEN_APOYO?.toString() ?? '123',
      query: 'api.con_login',
    }),
    headers: {
      'Content-type': 'text/plain',
    },
  })
    .then((response) => 
       response.json()
    )
    .then(async(response) => {
      const registro = response.DATA.datos.registro
      if(registro.valido === '1' || registro.valido === 1){
        obj.user.id = email;
        obj.user.username = email;
        obj.user.fullname = registro.nombre_empleado;
        obj.user.first_name = registro.nombre_empleado;
        obj.user.email = email;
        obj.user.codigoPlanilla = registro.codigo_planilla;
        actualiza_usuario(email,registro.codigo_planilla, setCurrentUser)
      }else{
        throw new Error('Error de autenticación');
      }
    })
    .catch((e) => {
      throw new Error('Usuario invalido');
    })
    return obj;
}
export async function autenticacionMs(email: string, setCurrentUser: any){
  await fetch(`${url}/datoLogin?key=${key}&usuario=${email}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
    .then((response) => 
       response.json()
    )
    .then(async(response) => {
      if(response.valido === '1' || response.valido === 1){
        actualiza_usuario(email,response.data.codigo_planilla, setCurrentUser)
      }else{
        throw new Error('Error de autenticación');
      }
    })
    .catch((e) => {
      throw new Error('Usuario invalido');
    })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
