/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";

import { loginRequest, callMsGraph } from "./AuthConfigMS";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .max(50, "Maximo 50 caracteres")
    .required("Usuario es requerido"),
  password: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .max(50, "Maximo 50 caracteres")
    .required("Password es requerido"),
});

const initialValues = {
  email: "",
  password: "",
};

export function Login() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, currentUser } = useAuth();
  const LoginMS = () => {
    instance.loginPopup(loginRequest).catch((e: any) => {
      console.log(e);
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setStatus("");
      setLoading(true);
      try {
        const { auth, user } = await login(
          values.email,
          values.password,
          setCurrentUser
        );
        saveAuth(auth);
        setCurrentUser(user);
      } catch (error) {
        if (error instanceof Error) {
          setStatus(error.message);
        } else {
          console.log("Datos incorrectos");
        }
        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  useEffect(() => {
    if (isAuthenticated) {
      if (currentUser === undefined) {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((responseT: any) => {
            callMsGraph(responseT.accessToken)
              .then((response: any) => {
                setCurrentUser({
                  id: response.id ?? "",
                  username: response.displayName ?? "",
                  password: undefined,
                  email: response.mail ?? "",
                  first_name: response.givenName ?? "",
                  last_name: response.givenName ?? "",
                  instance: instance,
                });
                saveAuth({ api_token: responseT.accessToken });
              })
              .catch(function (reason) {
                console.log(reason);
              });
          })
          .catch(function (reason) {
            console.log(reason);
          });
      } else {
      }
    }
  });
  return (
    <>
      <form
        className="form row"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-3">
          <h1 className="fw-bolder mb-3 font-title-res">PORTAL DE EMPLEADOS</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Login options */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label className="form-label fs-6 fw-bolder text-dark"></label>
          <input
            placeholder="Usuario"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-3">
          <label className="form-label fw-bolder text-dark fs-6 mb-0"></label>
          <input
            type="password"
            placeholder="Contraseña"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <label style={{ color: "red" }}>{formik.status}</label>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />

          {/* begin::Link */}
          <Link
            to="/auth/forgot-password"
            className="link-primary"
            style={{ display: "none" }}
          >
            Recupera tu clave
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className="d-grid mb-5" style={{ width: "100%" }}>
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn"
            style={{
              backgroundColor: "#1674F7",
              borderRadius: "15px",
              marginInline: "50px",
            }}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span
                className="indicator-label"
                style={{ color: "white", fontWeight: "bold" }}
              >
                INGRESAR
              </span>
            )}
            {loading && (
              <span
                className="indicator-progress"
                style={{ fontWeight: "bolder" }}
              >
                Cargando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>

        {/* end::Action */}
      </form>
    </>
  );
}
