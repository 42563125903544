/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { EnableSidebar } from "../../../_metronic/layout/core";
import { UserModel, useAuth } from "../auth";
import { KTIcon } from "../../../_metronic/helpers";

const Modal: FC<any> = ({ obj }) => {
  if ("cabecera" in obj) {
    return (
      <div className="modal fade" id="kt_modal_table_detail" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content rounded">
            <div className="modal-header">
              <h5 className="modal-title">Detalle de Vacaciones</h5>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div>
              <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Fecha de Inicio</th>
                        <th>Fecha Fin</th>
                        <th>Dias</th>
                      </tr>
                    </thead>
                    <tbody>
                      {"fecha_inicio" in obj.detalle ? (
                        <tr>
                          <td>{obj.detalle.fecha_inicio}</td>
                          <td>{obj.detalle.fecha_fin}</td>
                          <td>{obj.detalle.dias}</td>
                        </tr>
                      ) : (
                        obj.detalle.map(function (detail: any, index: number) {
                          return (
                            <tr key={`table_detail_${index}`}>
                              <td>{detail.fecha_inicio}</td>
                              <td>{detail.fecha_fin}</td>
                              <td>{detail.dias}</td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modal fade" id="kt_modal_table_detail" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content rounded">
            <div className="modal-header justify-content-end border-0 pb-0">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div>
              <div className="modal-body pt-0 pb-15 px-5 px-xl-20"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
const SolicitudVacaciones: FC = () => {
  const openModal = (detail: any) => {
    setObj(detail);
  };
  const [obj, setObj] = useState({});
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const consultaVacacion = (currentUser: UserModel | undefined) => {
    const url = process.env.REACT_APP_URL_API_APOYO?.toString() ?? "";
    if (url !== "" && currentUser !== undefined) {
      if (currentUser.codigoPlanilla !== "") {
        const key = process.env.REACT_APP_TOKEN_APOYO?.toString() ?? "123";
        fetch(`${url}/rh/vacacion`, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            empleado_id: currentUser.codigoPlanilla,
            token_app: key,
          }),
          headers: {
            "Content-type": "text/plain",
          },
        })
          .then((response) => response.json())
          .then((response) => {
            const dataApi = response.DATA;
            setData(dataApi);
          })
          .catch((e) => {});
      }
    }
  };

  useEffect(() => {
    consultaVacacion(currentUser);
  }, [currentUser]);
  return (
    <EnableSidebar>
      <>
        {" "}
        <h2>Listado de Vacaciones</h2>
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Periodo</th>
                <th>Días Derecho</th>
                <th>Dias Gozados</th>
                <th>Días Pendientes</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {data.map(function (detail: any, index: number) {
                return (
                  <tr key={`table_body_${index}`}>
                    <td>{index + 1}</td>
                    <td>{detail.cabecera.Periodo}</td>
                    <td>{detail.cabecera.Derecho}</td>
                    <td>{detail.cabecera.Pagado}</td>
                    <td>{detail.cabecera.Pendientess}</td>
                    <td>
                      <button
                        className="btn btn-success btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_table_detail"
                        onClick={() => {
                          openModal(detail);
                        }}
                      >
                        <span className="indicator-label">
                          <KTIcon iconName="book" />
                          &nbsp;
                        </span>
                        Detalle
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Modal obj={obj} />
      </>
    </EnableSidebar>
  );
};

export { SolicitudVacaciones };
