/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
const AuthLayout = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{ backgroundColor: "#182C59" }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/* begin::Wrapper */}
          <div
            className="w-lg-500px bg-body shadow-sm p-10 p-lg-15 mx-auto"
            style={{ borderRadius: "30px" }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              {windowSize.current[0] > 600 ? (
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/copher/logo.jpg")}
                ></img>
              ) : (
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/copher/logo.jpg")}
                ></img>
              )}
            </div>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}

        {/* end::Footer */}
      </div>
    </>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
